import React from "react";
import useThemeConfig from "src/themes/useThemeConfig";
import useProductGroups from "src/core/common/hooks/useProductGroups";

export default function ShowCaseProductsList({
  type,
  filters,
  actionsSortFilter,
  viewAllPosition,
  showBrands = false,
}) {
  const config = useThemeConfig();
  const ThemedShowCaseProductsList = config.components.ShowCasedProductsList;

  const {groups} = useProductGroups({filters, displayBrands: showBrands});

  const hasGroups = groups.length > 0;

  return (
    <ThemedShowCaseProductsList
      type={type}
      filters={filters}
      groups={groups}
      hasGroups={hasGroups}
      actionsSortFilter={actionsSortFilter}
      viewAllPosition={viewAllPosition}
    />
  );
}
